<div class="modal">
  <div class="modal__info">
    <div class="modal__info-column">
      <div class="modal__info-logo">
        <img src="/assets/images/logo.png" alt="PLAY logo" />
      </div>

      <div class="modal__info-title">
        {{
          deviceIsAuthenticated
            ? "Device authenticated"
            : "Device authentication"
        }}
      </div>
    </div>

    <div class="modal__info-column">
      <div class="modal__info-text-container">
        <div class="modal__info-text">
          {{
            deviceIsAuthenticated
              ? "You're all set! You can return to the game."
              : "Waiting for device authentication..."
          }}
        </div>
      </div>
    </div>

    <!-- <div class="modal__info-column">
      <div class="modal__info-text">
        <a
          *ngIf="deviceIsAuthenticated === true"
          [href]="url"
          class="button button__filled button__xs"
        >
          {{ "forms.returnToGame" | translate }}
        </a>
      </div>
    </div> -->
  </div>
  <app-alert
    class="auth-alert"
    *ngIf="alert"
    [type]="alert.type"
    [message]="alert.message"
  ></app-alert>
</div>
