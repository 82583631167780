<div class="modal">
  <div class="modal__login">
    <div>
      <div class="modal__login-logo">
        <img
          class="modal__logo"
          src="/assets/images/logo.png"
          alt="PLAY logo"
          width="150"
        />
      </div>
      <div class="modal__login-title">
        {{ "forms.walletPassword" | translate }}
      </div>
      <div class="modal__login-notice">
        <h6>Important Notice</h6>
        <p>Please read carefully before proceeding: This password is crucial for accessing your wallet and cannot be changed or retrieved later.</p>
        <ul>
          <li>Ensure you write it down and store it in a safe place.</li>
          <li>If you lose this password, you will permanently lose access to your digital assets.</li>
        </ul>
        <p>
          By proceeding, you acknowledge that you have read and understood this warning.
        </p>
      </div>
    </div>

    <div class="modal__login-form-column">
      <div class="modal__login-form form">
        <form [formGroup]="form" (ngSubmit)="createWallet()">
          <div class="form__item">
            <div class="form__field">
              <i class="icon-key form__item-icon"></i>
              <input
                formControlName="password"
                autocomplete="off"
                placeholder="{{ 'forms.password' | translate }}"
                [type]="passwordVisible ? 'text' : 'password'"
              />
              <i
                class="form__item-icon pw"
                [ngClass]="[passwordVisible ? 'icon-eye-off' : 'icon-eye']"
                (click)="passwordVisible = !passwordVisible"
              ></i>
            </div>
            <div
              class="form__field-error"
              *ngIf="showFieldErrs(form, 'password')"
            >
              {{ "forms.passwordRequired" | translate }}
            </div>
          </div>

          <div class="form__item">
            <div class="form__field">
              <i class="icon-key form__item-icon"></i>
              <input
                formControlName="passwordConfirm"
                autocomplete="off"
                placeholder="{{ 'forms.reenterPassword' | translate }}"
                [type]="passwordVisible ? 'text' : 'password'"
              />
              <i
                class="form__item-icon pw"
                [ngClass]="[passwordVisible ? 'icon-eye-off' : 'icon-eye']"
                (click)="passwordVisible = !passwordVisible"
              ></i>
            </div>
            <div class="form__field-error" *ngIf="showFormErrs(form)">
              {{ "forms.passwordsMismatch" | translate }}
            </div>
          </div>

          <div class="form__item form__item-submit">
            <button type="submit" [disabled]="form.invalid || form.disabled">
              {{ "forms.createWallet" | translate }}
              <i class="icon-arrow-right"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
