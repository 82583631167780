import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable, catchError, of } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { Alert } from 'src/app/components/alert/alert.interface';

@Component({
  templateUrl: 'device-authentication.component.html',
})
export class DeviceAuthenticationComponent implements OnInit {
  public alert!: Alert;
  public deviceIsAuthenticated = false;
  public url: SafeUrl = '';

  constructor(private readonly appService: AppService) {}

  ngOnInit(): void {
    const token = this.appService.returnRefreshToken
      ? this.appService.refreshToken || ''
      : this.appService.idToken || '';
    console.log(this.appService.deviceId);
    console.log(token);

    this.appService
      .completeDeviceAuthentication(this.appService.deviceId!, token)
      .pipe(
        catchError((error: any): Observable<any> => {
          this.alert = this.appService.mapError(error.error.message);
          return of();
        })
      )
      .subscribe((_) => {
        this.deviceIsAuthenticated = true;
      });
  }
}
